import axios from "axios";
import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaAccessibleIcon, FaEdit } from "react-icons/fa";
import { GrPrint } from "react-icons/gr";
import { IoMdCash } from "react-icons/io";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { ImPrinter } from "react-icons/im";

import {
  getAllContractTable,
  getAllContractTablePopulate,
  getAllContracts,
  getContractDetails,
} from "../../../actions/accountsactions/addcontract";
import FullScreenLoader from "../../../FullScreenLoader/FullScreenLoader";
import { ContractIndvidualPrint } from "../ContractPrint/ContractIndividualPrint";
import { ContractOfficalPrint } from "../ContractPrint/ContractOfficalPrint";
import MainContractPrint from "../ContractPrint/MainContractPrint";
import ContractInstallmentModal from "./contractInstallmentModal";
import UpdateContract from "./UpdateContract";
import { MdDeleteForever } from "react-icons/md";
import ContractNewPrint from "../ContractPrint/ContractNewPrint";
import { CSVDownload, CSVLink } from "react-csv";
import ContractModal from "./ContractModal";
import ContractNewPrint2 from "../ContractPrint/ContractNewPrint2";
import SelectStatusModal from "./SelectStatusModal";
import { getallunitcategory } from "../../../actions/getunit";

export const ContractTable = (props) => {
  console.log(props);
  const resultsallcontracts = useSelector((state) => state.getContractTable);
  const allcontracts = useSelector((state) => state.getContracts);

  const resultsallcontractspopulate = useSelector(
    (state) => state.getContractTablePopulate
  );
  const resultscontractdetails = useSelector(
    (state) => state.getContractDetails
  );
  const resultsCategory = useSelector((state) => state.getAllUnitCategory);

  const dispatch = useDispatch();
  const [contractinstallment, setcontractinstallment] = useState();
  const [printData, SetPrintData] = useState();
  const [printOfficalContractData, SetprintOfficalContractData] = useState();
  const [printOfficalContractData2, SetprintOfficalContractData2] = useState();
  const [statetoggle2, settoggle2] = useState(true);
  const [statetoggle, settoggle] = useState(true);
  const [contractDetailLoading, setContractDetailLoading] = useState(false);

  const [filerunits, setfilterunits] = useState();
  const [contracts, setContracts] = useState();

  const [statecontractid, setcontractid] = useState();
  const [showInstallModal, setshowInstallModal] = useState(false);
  const [installModalData, setinstallModalData] = useState(null);
  const [showUpdateModal, setshowUpdateModal] = useState(false);
  const [updateData, setupdateData] = useState(null);
  const [showfullScreenLoader, setshowfullScreenLoader] = useState(false);
  const [print, setPrint] = useState(false);
  const [print2, setPrint2] = useState(false);

  const [modal, setModal] = useState(false);

  const [paginationData, setpaginationData] = useState(null);
  const [loading, setloading] = useState(false);
  const [allUnits, setallUnits] = useState([]);
  const [allAffiliates, setallAffiliates] = useState([]);
  const [allContractDetails, setallContractDetails] = useState(null);

  const [allCustomers, setallCustomers] = useState([]);
  const [customerCnic, setcustomerCnic] = useState("");
  const [affiliateCnic, setaffiliateCnic] = useState("");
  const [unitId, setunitId] = useState("");
  const [contractRowdata, setcontractRowdata] = useState(null);
  const [disableBtn, setdisableBtn] = useState(true);
  const [loading2, setloading2] = useState(false);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const companyInfo = useSelector((state) => state.getCompany);
  const csvLinkRef = useRef(null);
  // print function
  const componentRef = useRef();

  const printIndvidualContract = useReactToPrint({
    content: () => componentRef.current,
  });
  // useEffect(() => {
  //     if (printData !== undefined) {
  //         printRecoveryBooking();
  //     }
  // }, [printData]);
  // // all projects print

  const AllcomponentRef = useRef();

  const AllprintPaymentRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });
  // contract offical print
  // print function
  const OfficalContractRef = useRef();
  const OfficalContractRef2 = useRef();

  const printOfficalContract = useReactToPrint({
    content: () => OfficalContractRef.current,
  });
  const printOfficalContract2 = useReactToPrint({
    content: () => OfficalContractRef2.current,
  });
  useEffect(() => {
    if (printOfficalContractData !== undefined) {
      printOfficalContract();
    }
  }, [printOfficalContractData, statetoggle]);
  useEffect(() => {
    if (printOfficalContractData2 !== undefined) {
      printOfficalContract2();
    }
  }, [printOfficalContractData2, statetoggle2]);
  //Table Data fiter
  const handlePrintInd = (val) => {
    if (parseFloat(val.AdvanceReceived) >= parseFloat(val.AdvanceAmount)) {
      setshowfullScreenLoader(true);
      dispatch(getContractDetails(val.ContractId, true));
    } else {
      toast.info(
        `${
          val.AdvanceAmount - val.AdvanceReceived
        }-PKR remaining to unlock file`
      );
    }
  };
  useEffect(() => {
    if (allContractDetails && print2) {
      SetprintOfficalContractData2(allContractDetails);
      settoggle2(!statetoggle2);
      setshowfullScreenLoader(false);
      setPrint2(false);
    } else if (allContractDetails && print) {
      SetprintOfficalContractData(allContractDetails);
      settoggle(!statetoggle);
      setshowfullScreenLoader(false);
      setPrint(false);
    } else if (allContractDetails && modal) {
      document.getElementById("modalofcontracttable").style.display = "block";

      // if(x.)
      // if (x.InstallmentStatus !== "CANCELLED") {
      SetPrintData(allContractDetails);

      setcontractinstallment(allContractDetails);
      setcontractid(allContractDetails.ContractId);
      setshowfullScreenLoader(false);
      setModal(false);
    }
  }, [allContractDetails, modal, print]);
  const onTextChange = () => {
    if (
      document.getElementById("categoryname")?.value === "" &&
      document.getElementById("unitfilterId")?.value === "" &&
      // document.getElementById("unitfilter").value === "" &&
      // document.getElementById("sectionfilter").value === "" &&
      // document.getElementById("statusfilter").value === "" &&
      document.getElementById("contractIdfilter").value === ""
      // && document.getElementById("statusfilter").value === ""
    ) {
      setfilterunits(resultsallcontracts.data?.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("unitfilterId").value,
          "i"
        );
        // const regex1 = new RegExp(
        //   document.getElementById("sectionfilter").value,
        //   "i"
        // );
        // const regex2 = new RegExp(
        //   document.getElementById("unitfilter").value,
        //   "i"
        // );
        // const regex3 = new RegExp(
        //   document.getElementById("statusfilter").value,
        //   "i"
        // );
        // const regex4 = new RegExp(
        //   document.getElementById("statusfilter").value,
        //   "i"
        // );
        const regex5 = new RegExp(
          document.getElementById("contractIdfilter").value,
          "i"
        );
        const regex6 = new RegExp(
          document.getElementById("categoryname").value,
          "i"
        );
        suggestions = resultsallcontracts.data?.data.sort().filter(
          (val) =>
            (document.getElementById("unitfilterId").value === "" ||
              regex.test(val.Unit.UnitName)) &&
            (document.getElementById("categoryname").value === "" ||
              regex.test(val?.UnitCategory?.CategoryName)) &&
            (document.getElementById("contractIdfilter").value === "" ||
              regex5.test(val.ContractId))
          // && (document.getElementById("sectionfilter").value == "" ||
          //   regex1.test(val.CustomerCnicNavigation.CustomerName)) &&
          // (document.getElementById("unitfilter").value == "" ||
          //   regex2.test(
          //     val.Manager !== undefined
          //       ? val.Manager.Employee.EmpName
          //       : null
          //   )) &&
          // (document.getElementById("statusfilter").value == "" ||
          //   regex3.test(val.AffiliateCnicNavigation.AffiliateName)) &&
          // (document.getElementById("statusfilter").value === "" ||
          //   regex4.test(val.ContractStatus))
        );
      }
      setfilterunits(suggestions);
    }
  };
  useEffect(() => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      ContractId: document.getElementById("contractIdfilter").value,
      UnitName: unitId,
      CustomerCnic: customerCnic,
      AffiliateCnic: affiliateCnic,
      Status: document.getElementById("statusfilter").value,
      isFile: props?.isFile,
    };
    setloading(true);
    dispatch(getAllContractTable(data));
    dispatch(getAllContractTablePopulate());
    props.isFile && dispatch(getallunitcategory());
  }, [dispatch, props?.isFile]);
  const openmodalcontracttable = (id) => {
    setshowfullScreenLoader(true);
    setallContractDetails(null);

    dispatch(getContractDetails(id, false));
    setModal(true);
  };
  const openmodalcontractcancel = (x) => {
    document.getElementById("modalofcontractcancel").style.display = "block";
  };
  const getPaginationData = (link) => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      ContractId: document.getElementById("contractIdfilter").value,
      UnitName: unitId,
      CustomerCnic: customerCnic,
      AffiliateCnic: affiliateCnic,
      Status: document.getElementById("statusfilter").value,
      isFile: props?.isFile,
    };

    dispatch(getAllContractTable(data, link));
    setloading(true);
  };
  function ongo() {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      ContractId: document.getElementById("contractIdfilter").value,
      UnitName: unitId,
      CustomerCnic: customerCnic,
      AffiliateCnic: affiliateCnic,
      Status: document.getElementById("statusfilter").value,
      UnitCategoryId:
        document.getElementById("categoryname")?.options[
          document.getElementById("categoryname").selectedIndex
        ]?.dataset?.id,
      isFile: props.isFile,
    };
    setloading(true);

    dispatch(getAllContractTable(data));
  }
  function canceldueductioncall() {
    if (
      document.getElementById("canceldueduction").value?.replace(/,/g, "") == ""
    ) {
      toast.warn("Please enter deduction amount");
      return;
    }
    document.getElementById("disablecancelcontract").disabled = true;
    axios({
      url: `${
        process.env.REACT_APP_API_URL
      }Contracts/Cancel?ContractId=${statecontractid}&DeductionAmount=${parseFloat(
        document.getElementById("canceldueduction").value?.replace(/,/g, "")
      )}`,
      method: "Put",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("disablecancelcontract").disabled = false;
        if (result.data.IsSuccess === true) {
          // emptyfields();

          toast.info(result.data.Message);
          document.getElementById("modalofcontractcancel").style.display =
            "none";
          document.getElementById("canceldueduction").value = "";

          let data = {
            ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
            From: document.getElementById("fromdate").value,
            To: document.getElementById("todate").value,
            ContractId: document.getElementById("contractIdfilter").value,
            UnitName: unitId,
            CustomerCnic: customerCnic,
            AffiliateCnic: affiliateCnic,
            Status: document.getElementById("statusfilter").value,
            isFile: props?.isFile,
          };
          dispatch(getAllContractTable(data));
        } else {
          toast.info(result.data.Message);
          document.getElementById("canceldueduction").value = "";
          document.getElementById("disablecancelcontract").disabled = false;
        }
      },
      (error) => {
        toast.info("Something went wrong!");
        document.getElementById("canceldueduction").value = "";
        document.getElementById("disablecancelcontract").disabled = false;
      }
    );
  }
  function generateComm(id) {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Contracts/GenerateCommission?contractId=${id}`,

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setshowfullScreenLoader(false);
        if (result.data.IsSuccess === true) {
          toast.info(result.data.Message);
          let data = {
            ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
            From: document.getElementById("fromdate").value,
            To: document.getElementById("todate").value,
            ContractId: document.getElementById("contractIdfilter").value,
            UnitName: unitId,
            CustomerCnic: customerCnic,
            AffiliateCnic: affiliateCnic,
            Status: document.getElementById("statusfilter").value,
            isFile: props?.isFile,
          };
          dispatch(getAllContractTable(data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        setshowfullScreenLoader(false);

        toast.info("Something went wrong!");
      }
    );
  }

  useEffect(() => {
    if (resultsallcontracts.data) {
      setpaginationData(resultsallcontracts.data);
      setfilterunits(resultsallcontracts?.data?.data);
      setloading(false);
    }
  }, [resultsallcontracts]);
  useEffect(() => {
    console.log(resultsallcontractspopulate);
    if (resultsallcontractspopulate.data) {
      setallAffiliates(resultsallcontractspopulate.data?.Affiliates);
      setallCustomers(resultsallcontractspopulate.data?.Buyers);
      setallUnits(resultsallcontractspopulate.data?.Units);
    }
  }, [resultsallcontractspopulate]);
  useEffect(() => {
    console.log("resultscontractdetails");
    if (resultscontractdetails.data) {
      setallContractDetails(resultscontractdetails.data.Data);
    }
  }, [resultscontractdetails]);
  console.log("allContractDetails", allContractDetails);
  const onTextChangeBuyer = () => {
    var inputValue = document.getElementById("buyerfilterId").value;
    var options = document.getElementById("buyerfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allCustomers.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;

        setcustomerCnic(val.CustomerCnicNavigation?.CustomerCnic);
        break;
      }
      setcustomerCnic("");
    }
  };
  const onTextChangeAffiliate = () => {
    var inputValue = document.getElementById("affiliatefilterId").value;
    var options = document.getElementById("affiliatefilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allAffiliates?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setaffiliateCnic(val.AffiliateCnicNavigation?.AffiliateCnic);
        break;
      }
      setaffiliateCnic("");
    }
  };
  const onTextChangeUnit = () => {
    var inputValue = document.getElementById("unitfilterId").value;
    var options = document.getElementById("unitfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allUnits.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setunitId(val.UnitName);
        break;
      }
      setunitId("");
    }
  };

  function showmodal2(data) {
    setcontractRowdata(data);
    document.getElementById("mymodal2").style.display = "block";
  }
  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    document.getElementById("contractVoucherEmail").value = "";
    document.getElementById("contractVoucherPassword").value = "";
    document.getElementById("contractVoucherReason").value = "";
  }

  function deleteContractVoucher() {
    if (document.getElementById("contractVoucherEmail").value === "") {
      return toast.info("Email is Required");
    } else if (
      document.getElementById("contractVoucherPassword").value === ""
    ) {
      return toast.info("Password is Required");
    } else if (document.getElementById("contractVoucherReason").value === "") {
      return toast.info("Reason is Required");
    }
    setloading2(true);
    setdisableBtn(false);
    const Email = document.getElementById("contractVoucherEmail").value;
    const Password = document.getElementById("contractVoucherPassword").value;
    const Reason = document.getElementById("contractVoucherReason").value;
    // const data={
    //     Email:Email,
    //   Password:Password,

    // }
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteContract?Email=${Email}&Password=${Password}&Reason=${Reason}&ContractId=${contractRowdata?.ContractId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("contractVoucherEmail").value = "";
          document.getElementById("contractVoucherPassword").value = "";
          document.getElementById("contractVoucherReason").value = "";
          let data = {
            ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
            From: document.getElementById("fromdate").value,
            To: document.getElementById("todate").value,
            ContractId: document.getElementById("contractIdfilter").value,
            UnitName: unitId,
            CustomerCnic: customerCnic,
            AffiliateCnic: affiliateCnic,
            Status: document.getElementById("statusfilter").value,
            isFile: props?.isFile,
          };
          setloading(true);
          dispatch(getAllContractTable(data));
          toast.info(result.data.Message);
          setdisableBtn(true);
          setloading2(false);
          setcontractRowdata(null);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          document.getElementById("contractVoucherEmail").value = "";
          document.getElementById("contractVoucherPassword").value = "";
          document.getElementById("contractVoucherReason").value = "";
          setdisableBtn(true);
          setloading2(false);
          // setvoucherRowdata(null)
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
    setloading(false);
  }

  const [CsvData, setCsvData] = useState([]);

  function showSelectStatusModal() {
    document.getElementById("selectCSVStatusModal").style.display = "block";
  }

  function downloadAllCsv(status) {
    setshowfullScreenLoader(true);

    let pgurl =
      status && status !== "ALL"
        ? `${
            process.env.REACT_APP_API_URL + "Contracts/GetAllContract?"
          }ProjectId=${Number(
            `${localStorage.getItem("ProjectId")}`
          )}&Status=${status}&IsFile=${props.isFile}`
        : `${
            process.env.REACT_APP_API_URL + "Contracts/GetAllContract?"
          }ProjectId=${Number(`${localStorage.getItem("ProjectId")}`)}&IsFile=${
            props.isFile
          }`;
    let arr = [];
    axios({
      url: pgurl,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")} `,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("result.data", result.data);
        if (result.data.Succeeded === true) {
          // setContracts(result.data.Data);
          result.data.Data?.forEach((val, index) => {
            let year = val.EntryDate.slice(0, 4);
            let day = val.EntryDate.slice(8, 10);
            let month = val.EntryDate.slice(5, 7);
            arr.push({
              Sr: index + 1,
              Date: day + "-" + month + "-" + year,
              "Contract No.": val.ContractId,
              Buyer: val.CustomerCnicNavigation.CustomerName,
              Affiliate: val?.AffiliateCnicNavigation?.AffiliateName,
              Manager: val.Manager?.Employee?.EmpName,
              ...(props.isFile
                ? {
                    Category: val.UnitCategory?.CategoryName,
                    Unit: val.Unit?.UnitName,
                  }
                : { Unit: val.Unit?.UnitName }),
              SaleRate: val.SaleRate?.toLocaleString(),
              Total: val.TotalPrice?.toLocaleString(),
              Receivable: val.CustomerReceivable?.toLocaleString(),
              Status: val.ContractStatus,
            });
          });
          setCsvData(arr);
          setshowfullScreenLoader(false);
        } else {
          toast.info(result.data.message);
          setshowfullScreenLoader(false);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
        setshowfullScreenLoader(false);
      }
    );
  }

  useEffect(() => {
    if (CsvData.length > 0) {
      if (csvLinkRef.current) {
        csvLinkRef.current.link.click();
      }
      setshowfullScreenLoader(false);
    }
  }, [CsvData]);
  return (
    <>
      <div hidden>
        {/* <ContractOfficalPrint
          ref={OfficalContractRef}
          printData={printOfficalContractData}
          companyInfo={companyInfo}
        />
        {/* <ContractNewPrint
          ref={OfficalContractRef}
          printData={printOfficalContractData}
        /> */}
      </div>
      <div hidden>
        <ContractIndvidualPrint
          ref={componentRef}
          printData={printData}
          isFile={props.isFile}
        />
      </div>
      <div hidden>
        <MainContractPrint
          ref={AllcomponentRef}
          printData={filerunits}
          isFile={props.isFile}
        />
      </div>
      {printOfficalContractData ? (
        <ContractNewPrint
          ref={OfficalContractRef}
          printData={printOfficalContractData}
          setPrintData={SetprintOfficalContractData}
          isFile={props.isFile}
        />
      ) : printOfficalContractData2 ? (
        <ContractNewPrint2
          ref={OfficalContractRef2}
          printData={printOfficalContractData2}
          setPrintData={SetprintOfficalContractData2}
          isFile={props.isFile}
        />
      ) : (
        <>
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="contractIdfilter"
                // onChange={onTextChange}
                placeholder="Contract "
              ></input>
            </div>

            {props.isFile ? (
              <>
                <div className="col-md-2 pr-0 pl-0">
                  <select id="categoryname" className="input_styling  ml-md-3">
                    <option value={""}>Select Category</option>
                    {resultsCategory?.data !== undefined
                      ? resultsCategory?.data?.map((x, index) => (
                          <option
                            data-id={x.UnitCategoryId}
                            data-idx={index}
                            key={index}
                          >
                            {x.CategoryName}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <input
                    className="input_styling ml-md-3"
                    id="unitfilterId"
                    autoComplete="off"
                    placeholder="Unit"
                    list="unitfilterIdOpt"
                    // onChange={onTextChangeUnit}
                  />
                  <datalist id="unitfilterIdOpt">
                    {allUnits?.map((x, idx) => (
                      <option index={idx} value={x.UnitName}>
                        {x.UnitId}
                      </option>
                    ))}
                  </datalist>
                </div>
              </>
            ) : (
              <div className="col-md-2 pr-0 pl-0">
                <input
                  className="input_styling ml-md-3"
                  id="unitfilterId"
                  autoComplete="off"
                  placeholder="Unit"
                  list="unitfilterIdOpt"
                  onChange={onTextChangeUnit}
                />
                <datalist id="unitfilterIdOpt">
                  {allUnits?.map((x, idx) => (
                    <option index={idx} value={x.UnitName}>
                      {x.UnitId}
                    </option>
                  ))}
                </datalist>
              </div>
            )}
            {/* <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Buyer"
          ></input> */}

            <div className="col-md-2 pr-0 pl-0">
              <input
                className="input_styling ml-md-3"
                id="buyerfilterId"
                autoComplete="off"
                placeholder="Buyer"
                list="buyerfilterIdOpt"
                onChange={onTextChangeBuyer}
              />
              <datalist id="buyerfilterIdOpt">
                {allCustomers?.map((x, idx) => (
                  <option
                    index={idx}
                    value={`${x.CustomerCnicNavigation?.CustomerName} (${
                      idx + 1
                    })`}
                  >
                    {x.CustomerCnicNavigation?.CustomerCnic2}
                  </option>
                ))}
              </datalist>
              {/* <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Buyer"
          ></input> */}
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                className="input_styling ml-md-3"
                id="affiliatefilterId"
                autoComplete="off"
                placeholder="Affiliate"
                list="affiliatefilterIdOpt"
                onChange={onTextChangeAffiliate}
              />
              <datalist id="affiliatefilterIdOpt">
                {allAffiliates?.map((x, idx) => (
                  <option
                    index={idx}
                    value={x.AffiliateCnicNavigation?.AffiliateName}
                  >
                    {x.AffiliateCnicNavigation?.AffiliateCnic}
                  </option>
                ))}
              </datalist>
              {/* <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Buyer"
          ></input> */}
            </div>
            {/* <div className="col-md-2 pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              id="unitfilter"
              onChange={onTextChange}
              placeholder="Manager "
            ></input>
          </div> */}

            <div className="col-md-1 pr-0 pl-0">
              {/* <input
            type="text "
            className="input_styling "
            id="statusfilter"
            onChange={onTextChange}
            placeholder="Status "
          ></input> */}

              <select
                // onChange={onTextChange}
                className="input_styling"
                id="statusfilter"
              >
                <option value="">Select Status</option>
                <option value="TRANSFERRED">TRANSFERRED</option>
                <option value="PENDING">PENDING</option>
                <option value="COMPLETED">COMPLETED</option>
                <option value="Re-Purchased">Re-Purchased</option>
                <option value="CANCELLED">CANCELLED</option>
              </select>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <ContractModal />
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {resultsallcontracts.loading ||
              loading ||
              resultsallcontractspopulate.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>

          <div className="row pt-2 ">
            {/* <div className="col-md-1 pr-0 pl-0">
  
  
                        <label className="input_label m-0">Search:</label>
                    </div>
                    <div className="col-md-3 pr-0 pl-0">
  
  
                        <input type="text " className="input_styling " placeholder="Unit/Buyer/Status" onChange={onTextChange}></input>
                    </div> */}
            <div className="col-md-3  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdate"
              ></input>
            </div>
            <div className="col-md-3 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todate"
                // defaultValue={moment().format("YYYY-MM-DD")}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button className="btn_Go" onClick={ongo}>
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0"></div>
          </div>
          <div className="row ">
            <div className=" col-12 mt-2  table-responsive pr-0 pl-0">
              <table className="table table-borderless m-0 table-hover cur_sor">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Date</th>
                    <th>Contract No.</th>
                    <th> Buyer</th>
                    <th> Affiliate</th>
                    {/* <th>Manager</th> */}

                    <th> {props.isFile ? "Category" : "Unit"} </th>
                    {props.isFile && <th> Unit </th>}

                    <th> SaleRate</th>
                    <th> Total</th>
                    <th> Receivable</th>
                    <th> Status</th>
                    <th className="text-center">Installments</th>

                    <th className="text-center">
                      {rolesdata?.data?.Access?.includes("C") ? (
                        <button
                          className="save_btn"
                          onClick={() => {
                            props.showcontracttable();
                          }}
                        >
                          New+
                        </button>
                      ) : null}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filerunits !== undefined
                    ? filerunits?.map((val, index) => {
                        // console.log(filerunits);
                        let year = val.EntryDate.slice(0, 4);
                        let day = val.EntryDate.slice(8, 10);
                        let month = val.EntryDate.slice(5, 7);
                        switch (month) {
                          case "01":
                            month = "Jan";

                            break;
                          case "02":
                            month = "Feb";

                            break;
                          case "03":
                            month = "Mar";

                            break;
                          case "04":
                            month = "Apr";

                            break;
                          case "05":
                            month = "May";

                            break;
                          case "06":
                            month = "Jun";

                            break;
                          case "07":
                            month = "Jul";

                            break;
                          case "08":
                            month = "Aug";

                            break;
                          case "09":
                            month = "Sep";

                            break;
                          case "10":
                            month = "Oct";
                            break;
                          case "11":
                            month = "Nov";
                            break;
                          case "12":
                            month = "Dec";
                            break;
                          default:
                            break;
                        }
                        // if (
                        //   // val.ContractStatus !== "TRANSFERRED" &&
                        //   //val.ContractStatus !== "CANCELLED"
                        // ) {
                        return (
                          <tr key={index}>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {index +
                                1 +
                                (paginationData.PageNumber - 1) *
                                  paginationData.PageSize}
                            </td>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {day + "-" + month + "-" + year}
                            </td>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {val.ContractId}
                            </td>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {val.CustomerCnicNavigation.CustomerName}
                            </td>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {val?.AffiliateCnicNavigation?.AffiliateName}
                            </td>
                            {/* <td
                          className="table_data"
                            onClick={() => {
                              openmodalcontracttable(val.ContractId);
                            }}
                          >
                            {val.Manager?.Employee?.EmpName}
                          </td> */}
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {props.isFile
                                ? val?.UnitCategory?.CategoryName
                                : val.Unit?.UnitName}
                            </td>
                            {props.isFile && (
                              <td
                                className="table_data"
                                onClick={() => {
                                  openmodalcontracttable(val.ContractId);
                                }}
                              >
                                {val.Unit?.UnitName || "N/A"}
                              </td>
                            )}
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {val.SaleRate?.toLocaleString()}
                            </td>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {val.TotalPrice?.toLocaleString()}
                            </td>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {val.CustomerReceivable?.toLocaleString()}
                            </td>
                            <td
                              className="table_data"
                              onClick={() => {
                                openmodalcontracttable(val.ContractId);
                              }}
                            >
                              {val.ContractStatus}
                            </td>
                            <td className="text-center">
                              <button
                                disabled={
                                  val.ContractStatus === "CANCELLED"
                                    ? true
                                    : val.InstallmentsCreated === false
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  // if(val?.ReSaleContract===true){
                                  if (
                                    val.AdvanceAmount === val.AdvanceReceived ||
                                    val?.ReSaleContract === true
                                  ) {
                                    setshowInstallModal(true);
                                    setinstallModalData(val);
                                    // }
                                  } else {
                                    toast.info("Please Clear Advance");
                                  }
                                }}
                                className="save_btn"
                              >
                                <small>Add</small>
                              </button>
                              &nbsp; &nbsp;
                              <ImPrinter
                                className="text-center cur_sor"
                                onClick={() => {
                                  setPrint2(true);
                                  setallContractDetails(null);
                                  handlePrintInd(val);
                                }}
                                size="1em"
                              />
                            </td>
                            <td>
                              {rolesdata?.data?.Access?.includes("U") ? (
                                <FaEdit
                                  onClick={() => {
                                    // if (val.InstallmentsCreated === false) {
                                    setupdateData(val);
                                    setshowUpdateModal(true);
                                    // } else {
                                    //   toast.info(`Can't edit advance amount `);
                                    // }
                                  }}
                                  size="1em"
                                />
                              ) : null}
                              &nbsp;
                              {localStorage.getItem("Designation") ===
                              "Admin" ? (
                                <MdDeleteForever
                                  onClick={() => {
                                    showmodal2(val);
                                  }}
                                  title="Delete"
                                  className="cur_sor"
                                   size="1em"
                                />
                              ) : null}
                              &nbsp; &nbsp;
                              {/* <IoMdCash
                                color={
                                  val?.IsCommissionGenerated === true
                                    ? "grey"
                                    : "black"
                                }
                                title={"Generate Commission"}
                                onClick={() => {
                                  if (val.IsCommissionGenerated === false) {
                                    setshowfullScreenLoader(true);
                                    generateComm(val.ContractId);
                                  } else {
                                    toast.info(`Can't Generte Commission`);
                                  }
                                }}
                                size="1em"
                              /> */}
                              {/* &nbsp; &nbsp; */}
                              <GrPrint
                                className="text-center cur_sor"
                                onClick={() => {
                                  setPrint(true);
                                  setallContractDetails(null);

                                  handlePrintInd(val);
                                }}
                                size="1em"
                              />
                            </td>
                          </tr>
                        );
                        // } else {
                        //   return null;
                        // }
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="col-6  text-right pr-0 pl-0">
              <button
                className="print_btn float-left"
                onClick={() => {
                  AllprintPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
                onClick={showSelectStatusModal}
              >
                Download CSV
              </button>
              <CSVLink
                hidden
                ref={csvLinkRef}
                style={{ color: "black" }}
                filename="Contract_Csv"
                data={CsvData}
              >
                Download CSV
              </CSVLink>
            </div>

            <div className="col-6  text-right pr-0 pl-0">
              <button
                disabled={paginationData?.LastPage ? false : true}
                className="print_btn float-right mx-1"
                onClick={() => {
                  getPaginationData(paginationData?.LastPage);
                }}
              >
                Last
              </button>
              <button
                disabled={paginationData?.PreviousPage ? false : true}
                className="print_btn float-right mx-1"
                onClick={() => {
                  getPaginationData(paginationData?.PreviousPage);
                }}
              >
                Previous
              </button>
              <button
                disabled={paginationData?.NextPage ? false : true}
                className="print_btn float-right mx-1"
                onClick={() => {
                  getPaginationData(paginationData?.NextPage);
                }}
              >
                Next
              </button>
              <button
                disabled={paginationData?.FirstPage ? false : true}
                className="print_btn float-right pl-1 mx-1"
                onClick={() => {
                  getPaginationData(paginationData?.FirstPage);
                }}
              >
                First
              </button>
            </div>
          </div>
        </>
      )}
      <div
        id="modalofcontracttable"
        class="modalscreen "
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div
          class="modal-content-screen1 pt-1"
          style={{
            height: "90vh",
            overflowY: "scroll",
          }}
        >
          <div className="row sticky-top">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Contract Detail</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById(
                    "modalofcontracttable"
                  ).style.display = "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 mt-2">
                <div className="table-responsive_modal table_height-voucher mt-4 ">
                  <div className="text-center">
                    <b>Transactions Detail</b>
                  </div>
                  <table className="table table-borderless m-0 ">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Submit On</th>
                        <th>Account</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contractinstallment !== undefined
                        ? contractinstallment.Transactions.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {moment(x.EntryDate?.slice(0, 10)).format(
                                  "DD-MMM-YYYY"
                                )}
                              </td>
                              <td>{x.Account.AccountTitle}</td>
                              <td>
                                {x.TransactionType === "DEBIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>

                              <td>
                                {x.TransactionType === "CREDIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Project:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.Project !== undefined
                      ? contractinstallment.Project.ProjectName
                      : null}
                  </li>
                  {props.isFile ? (
                    <>
                      <li>
                        <b> Category:</b>{" "}
                        {contractinstallment !== undefined &&
                        contractinstallment?.UnitCategory !== undefined
                          ? contractinstallment?.UnitCategory?.CategoryName
                          : null}
                      </li>
                      <li>
                        <b> Unit:</b>{" "}
                        {(contractinstallment !== undefined &&
                        contractinstallment.Unit !== undefined
                          ? contractinstallment?.Unit?.UnitName
                          : "N/A") || "N/A"}
                      </li>
                    </>
                  ) : (
                    <li>
                      <b> Unit:</b>{" "}
                      {contractinstallment !== undefined &&
                      contractinstallment.Unit !== undefined
                        ? contractinstallment.Unit.UnitName
                        : null}
                    </li>
                  )}
                  <li>
                    <b> Affiliate:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.AffiliateCnicNavigation !== undefined
                      ? contractinstallment.AffiliateCnicNavigation
                          .AffiliateName
                      : null}
                  </li>
                  <li>
                    <b> Customer:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.CustomerCnicNavigation !== undefined
                      ? contractinstallment.CustomerCnicNavigation.CustomerName
                      : null}
                  </li>
                  <li>
                    <b> Manger:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.Manager !== undefined
                      ? contractinstallment.Manager.Employee.EmployeeName
                      : null}
                  </li>
                  <li>
                    <b> Nominee:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.Nominee !== undefined
                      ? contractinstallment.Nominee?.NomineeName
                      : null}
                  </li>
                  <li>
                    <b>Marla:</b>{" "}
                    {props.isFile
                      ? contractinstallment !== undefined &&
                        contractinstallment.UnitCategory !== undefined
                        ? contractinstallment.UnitCategory.CategoryName
                        : null
                      : contractinstallment !== undefined &&
                        contractinstallment.Unit !== undefined
                      ? contractinstallment.Unit.NetMarla
                      : null}
                  </li>
                  <li>
                    {props.isFile ? null : (
                      <>
                        <b>Sqft:</b>{" "}
                        {contractinstallment !== undefined &&
                        contractinstallment.Unit !== undefined
                          ? contractinstallment.Unit.NetSqft
                          : null}
                      </>
                    )}
                  </li>

                  <>
                    <li>
                      <b>Dimension(LxW):</b>{" "}
                      {(contractinstallment !== undefined &&
                      contractinstallment?.Unit
                        ? contractinstallment?.Unit?.DimensionLength +
                          "x" +
                          contractinstallment?.Unit?.DimensionWidth
                        : null) || "N/A"}
                    </li>
                    <li>
                      {(contractinstallment !== undefined &&
                        contractinstallment?.Unit !== undefined &&
                        contractinstallment?.Unit?.RatePerMarlaOrsqft ==
                          "MARLA") ||
                      props.isFile ? (
                        <b>Rate/Marla:</b>
                      ) : (
                        <b>Rate/Sqft:</b>
                      )}{" "}
                      {contractinstallment !== undefined &&
                      contractinstallment?.Unit !== undefined
                        ? contractinstallment?.Unit?.RatePerMarla.toLocaleString() ||
                          contractinstallment.SaleRate.toLocaleString()
                        : null}
                    </li>
                  </>

                  <li>
                    <b> Advance:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.AdvanceAmount !== null
                      ? contractinstallment.AdvanceAmount.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b> Status:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.ContractStatus !== null
                      ? contractinstallment.ContractStatus
                      : null}
                  </li>
                </ul>
              </div>
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Date:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.EntryDate !== undefined
                      ? moment(
                          contractinstallment.EntryDate.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : null}
                  </li>
                  <li>
                    <b> Sale Rate:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.SaleRate !== null
                      ? contractinstallment.SaleRate.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Total Price:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.TotalPrice !== null
                      ? contractinstallment.TotalPrice.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Receivable:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.CustomerReceivable !== null
                      ? contractinstallment.CustomerReceivable.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b> Commission Exp%:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.ComissionPer !== null
                      ? contractinstallment.ComissionPer
                      : null}
                  </li>
                  <li>
                    <b> Commission Exp:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.ComissionAmount !== null
                      ? contractinstallment.ComissionAmount.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b> Commission Inc%:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.CompanyCommissionPer !== null
                      ? contractinstallment.CompanyCommissionPer.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b> Commission Inc:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.CompanyCommissionAmount !== null
                      ? contractinstallment.CompanyCommissionAmount
                      : null}
                  </li>
                  <li>
                    <b> Additional Margin%:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.AdditionalMarginPer !== null
                      ? contractinstallment.AdditionalMarginPer
                      : null}
                  </li>
                  <li>
                    <b> Additional Margin:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.AdditionalMarginAmount !== null
                      ? contractinstallment.AdditionalMarginAmount.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b> Reserved Commission %:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.ReservedCommissionPer !== null
                      ? contractinstallment.ReservedCommissionPer
                      : 0}
                  </li>
                  <li>
                    <b> Reserved Commission:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.ReservedCommissionAmount !== null
                      ? contractinstallment.ReservedCommissionAmount?.toLocaleString()
                      : 0}
                  </li>
                  <li>
                    <b> Admin Share %:</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.ReservedCommissionPer !== null
                      ? contractinstallment.AdminSharePer
                      : 0}
                  </li>
                  <li>
                    <b> Admin Share :</b>{" "}
                    {contractinstallment !== undefined &&
                    contractinstallment.ReservedCommissionAmount !== null
                      ? contractinstallment.AdminShareAmount?.toLocaleString()
                      : 0}
                  </li>
                </ul>
              </div>
            </div>
            {contractinstallment?.ContractJointBuyers?.length > 0 && (
              <>
                <div className="text-center">
                  <b>Joint Buyers</b>
                </div>
                <table class="table table-hover">
                  <thead class="purple whiteText tableCash">
                    <tr className="purple whiteText">
                      <th>Name</th>

                      <th>CNIC </th>
                      <th>Address</th>
                    </tr>
                  </thead>

                  <tbody>
                    {contractinstallment !== undefined
                      ? contractinstallment?.ContractJointBuyers?.map(
                          (buyer, idx) => (
                            <tr key={idx}>
                              <td>
                                {buyer?.CustomerCnicNavigation?.CustomerName}
                              </td>
                              <td>
                                {buyer?.CustomerCnicNavigation?.CustomerCnic2}
                              </td>
                              <td>
                                {buyer?.CustomerCnicNavigation?.CustomerAddress}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </table>
              </>
            )}
            <div className="text-center">
              <b>Nominees</b>
            </div>
            <table class="table table-hover">
              <thead class="purple whiteText tableCash">
                <tr className="purple whiteText">
                  <th>Name</th>

                  <th>CNIC </th>
                  <th>Cell No.</th>
                  <th>Address</th>
                  {/* <th>City</th> */}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{contractinstallment?.Nominee?.NomineeName}</td>
                  <td>{contractinstallment?.Nominee?.NomineeCnic}</td>
                  <td>{contractinstallment?.Nominee?.NomineeCellNoOne}</td>
                  <td>{contractinstallment?.Nominee?.NomineeAddress}</td>
                  {/* <td>{contractinstallment?.Nominee?.NomineeCity}</td> */}
                </tr>
                {contractinstallment?.SecondNominee && (
                  <tr>
                    <td>{contractinstallment?.SecondNominee?.NomineeName}</td>
                    <td>{contractinstallment?.SecondNominee?.NomineeCnic}</td>
                    <td>
                      {contractinstallment?.SecondNominee?.NomineeCellNoOne}
                    </td>
                    <td>
                      {contractinstallment?.SecondNominee?.NomineeAddress}
                    </td>
                    {/* <td>{contractinstallment?.Nominee?.NomineeCity}</td> */}
                  </tr>
                )}
              </tbody>
            </table>
            <div className="text-center">
              <b>Advance</b>
            </div>
            <table class="table table-hover">
              <thead class="purple whiteText tableCash">
                <tr className="purple whiteText">
                  <th>Total</th>

                  <th scope="col">Pending </th>
                  <th scope="col">Received</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {contractinstallment !== undefined ? (
                  <tr>
                    <td>
                      {contractinstallment.AdvanceAmount !== undefined
                        ? contractinstallment.AdvanceAmount.toLocaleString()
                        : 0}
                    </td>
                    <td>
                      {(
                        contractinstallment.AdvanceAmount -
                        contractinstallment.AdvanceReceived
                      ).toLocaleString()}
                    </td>
                    <td>
                      {contractinstallment.AdvanceReceived.toLocaleString()}
                    </td>
                    <td className="ml-2">
                      {contractinstallment.AdvanceAmount -
                        contractinstallment.AdvanceReceived !==
                      0
                        ? "Pending"
                        : "Paid"}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            {localStorage.getItem("ProjectOwner") !== "OTHER" && (
              <>
                <div className="text-center">
                  <b>Installments</b>
                </div>
                <table class="table ">
                  <thead class="purple whiteText">
                    <tr>
                      <th scope="col">Install No.</th>
                      <th scope="col">Due Date</th>
                      <th scope="col">Total</th>
                      <th scope="col">Pending </th>
                      <th scope="col">Received</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contractinstallment === undefined ||
                    contractinstallment.ContractInstallments === undefined
                      ? // contractinstallment.length == 0
                        null
                      : contractinstallment.ContractInstallments.map(
                          (x, id) => {
                            return (
                              <tr key={id}>
                                <td>{x.InstallmentNo}</td>
                                <td>
                                  {moment(x.DueDate?.split("T")[0]).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{x.DueAmount?.toLocaleString()}</td>
                                <td>
                                  {(
                                    x.DueAmount - x.ReceivedAmount
                                  ).toLocaleString()}
                                </td>
                                <td>{x.ReceivedAmount?.toLocaleString()}</td>
                                <td>{x.InstallmentStatus}</td>
                              </tr>
                            );
                          }
                        )}
                  </tbody>
                </table>
              </>
            )}
            <div className="row">
              <div className=" col-12 text-right pr-0 pl-0">
                {localStorage?.getItem("Designation") === "Admin" ? (
                  rolesdata?.data?.Access?.includes("U") ? (
                    <button
                      className="cancel_btn mr-2"
                      onClick={() => {
                        openmodalcontractcancel();
                        document.getElementById(
                          "modalofcontracttable"
                        ).style.display = "none";
                      }}
                    >
                      Cancel Contract
                    </button>
                  ) : null
                ) : null}

                <button
                  className="print_btn float-right"
                  onClick={() => {
                    printIndvidualContract();
                  }}
                >
                  <TiPrinter size="1.3em" />
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mymodal2" class="modal">
        <div class="modal-content-cat_voucher2">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Delete Voucher</h4>
              <span class="close pr-2  " onClick={closemodal2}>
                &times;
              </span>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Email:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Email"
                id="contractVoucherEmail"
                type="email"
              ></input>
            </div>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Password:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Password"
                id="contractVoucherPassword"
              ></input>
            </div>
            <div className="col-12  " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Reason:</label>
            </div>
            <div className="col-12  " style={{ width: "100%" }}>
              <textarea
                style={{ width: "100%", height: "100px" }}
                className="input_total_styling "
                placeholder="Reason"
                id="contractVoucherReason"
                rows="4"
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                float: "right",
                width: "100%",
                justifyContent: "end",
                marginRight: "3%",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              {loading2 && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
              <div>
                <span>
                  <button
                    className="cancel_btn ml-2  "
                    onClick={() => {
                      closemodal2();
                      setcontractRowdata(null);
                    }}
                  >
                    Cancel
                  </button>
                </span>
              </div>
              <div>
                <span>
                  <button
                    className="save_btn ml-2  "
                    disabled={!disableBtn}
                    onClick={deleteContractVoucher}
                  >
                    Save
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="modalofcontractcancel" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Cancel Contract</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById(
                    "modalofcontractcancel"
                  ).style.display = "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2 mt-3 text-left p-0">
              <label className="input_label m-0 ">Deduction:</label>
            </div>
            <div className="col-md-4 ">
              <input
                type="text "
                className="input_styling   mt-3"
                id="canceldueduction"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    e.target.value = parseInt(
                      document
                        .getElementById("canceldueduction")
                        .value?.replace(/,/g, "")
                    ).toLocaleString();
                  } else {
                    e.target.value = "0";
                  }
                }}
              ></input>
            </div>
            <div className=" col-12  text-right pr-0 pl-0">
              <button
                className="cancel_btn mr-2"
                onClick={() => {
                  canceldueductioncall();
                }}
                id="disablecancelcontract"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {showInstallModal && (
        <ContractInstallmentModal
          setshowModal={setshowInstallModal}
          modalData={installModalData}
          isFile={props.isFile}
        />
      )}
      {showUpdateModal && (
        <UpdateContract
          setshowModal={setshowUpdateModal}
          updateData={updateData}
          isFile={props.isFile}
        />
      )}
      {showfullScreenLoader && (
        <FullScreenLoader setshowModal={setshowfullScreenLoader} />
      )}
      {/* <ContractInstallmentModal setshoModal={setinstallModalData} modalData={installModalData} /> */}
      <SelectStatusModal downloadAllCsv={downloadAllCsv} />
    </>
  );
};
