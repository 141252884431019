import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

export class ContractIndvidualPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Contract Detail - {localStorage.getItem("ProjectName")} </u>
            </h4>
            <div className="row">
              <div className="col-12 mt-2">
                <div className=" mt-4 ">
                  <div className="text-center">
                    <b>Transactions Detail</b>
                  </div>
                  <table className="table table-borderless m-0 ">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Account</th>

                        <th>Debit</th>

                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.printData !== undefined
                        ? this.props.printData.Transactions?.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{x.Account?.AccountTitle}</td>
                              <td>
                                {x.TransactionType === "DEBIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>

                              <td>
                                {x.TransactionType === "CREDIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row pt-4">
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Project:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.Project !== undefined
                      ? this.props.printData.Project.ProjectName
                      : null}
                  </li>
                  <li>
                    {this.props.isFile ? (
                      <>
                        <b> Category:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.UnitCategory !== undefined
                          ? this.props.printData.UnitCategory.CategoryName
                          : null}
                      </>
                    ) : (
                      <>
                        <b> Unit:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.Unit !== undefined
                          ? this.props.printData.Unit.UnitName
                          : null}
                      </>
                    )}
                  </li>
                  <li>
                    <b> Affiliate:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.AffiliateCnicNavigation !== undefined
                      ? this.props.printData.AffiliateCnicNavigation
                          .AffiliateName
                      : null}
                  </li>
                  <li>
                    <b> Customer:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.CustomerCnicNavigation !== undefined
                      ? this.props.printData.CustomerCnicNavigation.CustomerName
                      : null}
                  </li>
                  <li>
                    <b> Manger:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.Manager !== undefined
                      ? this.props.printData.Manager.Employee.EmployeeName
                      : null}
                  </li>
                  <li>
                    <b>Area:</b>{" "}
                    {this.props.isFile
                      ? this.props.printData !== undefined &&
                        this.props.printData.UnitCategory !== undefined
                        ? this.props.printData.UnitCategory.CategoryName
                        : null
                      : this.props.printData !== undefined &&
                        this.props.printData.Unit !== undefined
                      ? this.props.printData.Unit.NetMarla +
                        " M" 
                      : null}
                  </li>
                  {this.props.isFile ? null : (
                    <>
                      <li>
                        <b>Sqft:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.Unit !== undefined
                          ? this.props.printData.Unit.NetSqft
                          : null}
                      </li>

                      <li>
                        <b>Dimension(LxW):</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData.Unit !== undefined
                          ? this.props.printData.Unit.DimensionLength +
                            "x" +
                            this.props.printData.Unit.DimensionWidth
                          : null}
                      </li>

                      <li>
                        <b>Rate Per Marla:</b>{" "}
                        {this.props.printData !== undefined &&
                        this.props.printData?.Unit !== undefined
                          ? this.props?.printData?.Unit?.RatePerMarla?.toLocaleString()
                          : null}
                      </li>
                    </>
                  )}
                  <li>
                    <b> Advance:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.AdvanceAmount !== null
                      ? this.props.printData.AdvanceAmount
                      : null}
                  </li>
                  <li>
                    <b> Status:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.ContractStatus !== null
                      ? this.props.printData.ContractStatus
                      : null}
                  </li>
                </ul>
              </div>
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Date:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.EntryDate !== undefined
                      ? moment(
                          this.props.printData.EntryDate.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : null}
                  </li>
                  <li>
                    <b> Sale Rate:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.SaleRate !== null
                      ? this.props.printData.SaleRate
                      : null}
                  </li>
                  <li>
                    <b>Total Price:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.TotalPrice !== null
                      ? this.props.printData.TotalPrice.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Receivable:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.CustomerReceivable !== null
                      ? this.props.printData.CustomerReceivable
                      : null}
                  </li>
                  <li>
                    <b> Commission Exp%:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.ComissionPer !== null
                      ? this.props.printData.ComissionPer
                      : null}
                  </li>
                  <li>
                    <b> Commission Exp:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.ComissionAmount !== null
                      ? this.props.printData.ComissionAmount
                      : null}
                  </li>
                  <li>
                    <b> Commission Inc%:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.CompanyCommissionPer !== null
                      ? this.props.printData.CompanyCommissionPer
                      : null}
                  </li>
                  <li>
                    <b> Commission Inc:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.CompanyCommissionAmount !== null
                      ? this.props.printData.CompanyCommissionAmount
                      : null}
                  </li>
                  <li>
                    <b> Additional Margin%:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.AdditionalMarginPer !== null
                      ? this.props.printData.AdditionalMarginPer
                      : null}
                  </li>
                  <li>
                    <b> Additional Margin:</b>{" "}
                    {this.props.printData !== undefined &&
                    this.props.printData.AdditionalMarginAmount !== null
                      ? this.props.printData.AdditionalMarginAmount
                      : null}
                  </li>
                </ul>
              </div>
            </div>
            {this.props.printData?.ContractJointBuyers?.length > 0 && (
              <>
                <div className="text-center">
                  <b>Joint Buyers</b>
                </div>
                <table class="table table-hover">
                  <thead class="purple whiteText tableCash">
                    <tr className="purple whiteText">
                      <th>Name</th>

                      <th>CNIC </th>
                      <th>Address</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData !== undefined
                      ? this.props.printData?.ContractJointBuyers?.map(
                          (buyer, idx) => (
                            <tr key={idx}>
                              <td>
                                {buyer?.CustomerCnicNavigation?.CustomerName}
                              </td>
                              <td>
                                {buyer?.CustomerCnicNavigation?.CustomerCnic2}
                              </td>
                              <td>
                                {buyer?.CustomerCnicNavigation?.CustomerAddress}
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </table>
              </>
            )}
            <div className="text-center">
              <b>Nominees</b>
            </div>
            <table class="table table-hover">
              <thead class="purple whiteText tableCash">
                <tr className="purple whiteText">
                  <th>Name</th>

                  <th>CNIC </th>
                  <th>Cell No.</th>
                  <th>Address</th>
                  {/* <th>City</th> */}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{this.props.printData?.Nominee?.NomineeName}</td>
                  <td>{this.props.printData?.Nominee?.NomineeCnic}</td>
                  <td>{this.props.printData?.Nominee?.NomineeCellNoOne}</td>
                  <td>{this.props.printData?.Nominee?.NomineeAddress}</td>
                  {/* <td>{this.props.printData?.Nominee?.NomineeCity}</td> */}
                </tr>
                {this.props.printData?.SecondNominee && (
                  <tr>
                    <td>{this.props.printData?.SecondNominee?.NomineeName}</td>
                    <td>{this.props.printData?.SecondNominee?.NomineeCnic}</td>
                    <td>
                      {this.props.printData?.SecondNominee?.NomineeCellNoOne}
                    </td>
                    <td>
                      {this.props.printData?.SecondNominee?.NomineeAddress}
                    </td>
                    {/* <td>{this.props.printData?.Nominee?.NomineeCity}</td> */}
                  </tr>
                )}
              </tbody>
            </table>
            <div className="mt-3">
              <div className="text-center">
                <b>Advance</b>
              </div>
              <table className="table table-hover">
                <thead className="purple whiteText tableCash">
                  <tr className="purple whiteText">
                    <th>Total</th>

                    <th scope="col">Pending </th>
                    <th scope="col">Received</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.printData !== undefined ? (
                    <tr>
                      <td>
                        {this.props.printData?.AdvanceAmount !== undefined
                          ? this.props.printData.AdvanceAmount?.toLocaleString()
                          : 0}
                      </td>

                      <td>
                        {(
                          this.props.printData?.AdvanceAmount -
                          this.props.printData.AdvanceReceived
                        )?.toLocaleString()}
                      </td>
                      <td>
                        {this.props.printData?.AdvanceReceived?.toLocaleString()}
                      </td>

                      <td className="ml-2">
                        {this.props.printData.AdvanceAmount -
                          this.props.printData.AdvanceReceived !==
                        0
                          ? "Pending"
                          : "Paid"}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <div className="text-center">
                <b>Installments</b>
              </div>
              <table className="table ">
                <thead className="purple whiteText">
                  <tr>
                    <th scope="col">Install No.</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Pending </th>
                    <th scope="col">Received</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.printData === undefined ||
                  this.props.printData?.ContractInstallments === undefined
                    ? // this.props.printData.length == 0
                      null
                    : this.props.printData.ContractInstallments?.map(
                        (x, id) => (
                          <tr key={id}>
                            <td>{x.InstallmentNo}</td>
                            <td>
                              {moment(x.DueDate?.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{x.DueAmount?.toLocaleString()}</td>
                            <td>
                              {(
                                x.DueAmount - x.ReceivedAmount
                              )?.toLocaleString()}
                            </td>
                            <td>{x.ReceivedAmount?.toLocaleString()}</td>
                            <td>{x.InstallmentStatus}</td>
                          </tr>
                        )
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
