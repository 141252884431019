import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import { getallSection } from "../../../actions/getsections";
import {
  getalldimension,
  getallmeasurment,
  getallunitcategory,
  getallunits,
  getallunittags,
} from "../../../actions/getunit";
import { getdealboughtpopulatetable } from "../../../actions/inventoryactions/dealbought";
import { getallland } from "../../../actions/inventoryactions/getlandaction";

export const Unit = (props) => {
  const resultsproject = useSelector((state) => state.getProject);
  const resultsCategory = useSelector((state) => state.getAllUnitCategory);
  const resultsTags = useSelector((state) => state.getAllUnitTags);

  // const resultsdimensions = useSelector((state) => state.getAllDimensionUnit);
  const resultallland = useSelector((state) => state.getAllLand);
  const resultsalldealbought = useSelector((state) => state.getDealBoughtRed);
  // const resultsmeasurement = useSelector(state => state.getAllMeasureUnit);
  // const resultsallsections = useSelector((state) => state.getAllSections);
  const resultsallunits = useSelector((state) => state.getAllUnits);
  let dispatch = useDispatch();
  const [stateSectionList, setSectionList] = useState([]);
  const [stateCategoryList, setCategoryList] = useState([]);
  const [stateTagsList, setTagsList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [stateprojectid, setprojectid] = useState(0);

  const [loader, setloader] = useState(false);

  const [state, setstate] = useState({
    UnitId: 0,
    UnitName: null,
    // AreaValue: 0,
    // AreaMeasureUnitId: 0,
    DimensionLength: null,
    DimensionWidth: null,
    // DimensionUnitId: 0,
    SectionId: 0,
    ProjectId: 0,
    Price: null,
    Marla: null,
    Sarsai: null,
    NetMarla: null,
    RatePerMarla: null,
    DealBoughtId: null,
    LandPurchaseId: null,
    CostPerMarla: null,
    NetSqft: null,
    RatePerMarlaORSqft: null,
    TotalCost: null,
  });
  const savedata = () => {
    if (
      document.getElementById("marlas").value === "" ||
      // document.getElementById("dimensionlenght").value === "" ||
      // document.getElementById("dimentionwidth").value === "" ||
      document.getElementById("unitname").value === "" ||
      document.getElementById("projectname").options[
        document.getElementById("projectname").selectedIndex
      ].value === "Select" ||
      document.getElementById("sectionname").options[
        document.getElementById("sectionname").selectedIndex
      ].value === "Select" ||
      document.getElementById("categoryname").options[
        document.getElementById("categoryname").selectedIndex
      ].value === "Select" ||
      document.getElementById("sarsai").value === "" ||
      document.getElementById("netmarlas").value === ""
    ) {
      toast.info("All fields are required");
    } else if (
      document.getElementById("deals") !== null
        ? document.getElementById("deals").selectedIndex === 0
        : null && document.getElementById("land") !== null
        ? document.getElementById("land").selectedIndex === 0
        : null
    ) {
      toast.info("Select Land/Deal after Project Selection");
    } else if (
      document.getElementById("plotRate").value === "" ||
      document.getElementById("plotNet").value === ""
    ) {
      toast.info("Enter  Sale Rate");
    } else if (document.getElementById("Costpermarla").value === "") {
      toast.info("Enter  Cost Rate");
    } else if (
      parseFloat(
        document.getElementById("Costpermarla").value.replace(/,/g, "")
      ) >
      parseFloat(document.getElementById("plotRate").value.replace(/,/g, ""))
    ) {
      toast.info(
        "Cost/marla value should be less than or equal to Sale rate/marla"
      );
    } else {
      let data = state;

      data.UnitId = 0;
      data.ProjectId =
        document.getElementById("projectname").options[
          document.getElementById("projectname").selectedIndex
        ].dataset.id;
      data.SectionId =
        document.getElementById("sectionname").options[
          document.getElementById("sectionname").selectedIndex
        ].dataset.id;
      data.CategoryId =
        document.getElementById("categoryname").options[
          document.getElementById("categoryname").selectedIndex
        ].dataset.id;
      data.UnitTag = selectedTags;
      // [
      //   {
      //     tagId:
      //       document.getElementById("tagname").options[
      //         document.getElementById("tagname").selectedIndex
      //       ].dataset.id,
      //   },
      // ];
      data.Marla = document.getElementById("marlas").value;
      data.Sarsai = parseFloat("0." + document.getElementById("sarsai").value);
      data.NetMarla = document.getElementById("netmarlas").value;
      data.RatePerMarla = parseInt(
        document.getElementById("plotRate").value.replace(/,/g, "")
      );
      data.DimensionLength = parseFloat(
        document.getElementById("dimensionlenght").value
      );
      data.DimensionWidth = parseFloat(
        document.getElementById("dimentionwidth").value
      );
      // data.DimensionUnitId = document.getElementById("dimensionsize").options[document.getElementById("dimensionsize").selectedIndex].dataset.id;
      data.UnitName = document.getElementById("unitname").value;
      data.Price = parseFloat(
        document.getElementById("plotNet").value.replace(/,/g, "")
      );
      data.LandPurchaseId =
        document.getElementById("land") !== null
          ? document.getElementById("land").selectedIndex === 0
            ? data.LandPurchaseId
            : document.getElementById("land").options[
                document.getElementById("land").selectedIndex
              ].dataset.id
          : null;
      data.DealBoughtId =
        document.getElementById("deals") !== null
          ? document.getElementById("deals").selectedIndex === 0
            ? data.DealBoughtId
            : document.getElementById("deals").options[
                document.getElementById("deals").selectedIndex
              ].dataset.id
          : null;
      data.CostPerMarla = parseInt(
        document.getElementById("Costpermarla").value.replace(/,/g, "")
      );
      data.NetSqft = parseFloat(
        document.getElementById("netsqft").value.replace(/,/g, "")
      );
      data.RatePerMarlaORSqft =
        document.getElementById("radiomarla").checked === true
          ? "Marla"
          : "Sqft";
      data.TotalCost = parseFloat(
        document.getElementById("totalcost").value.replace(/,/g, "")
      );

      document.getElementById("unitsave").disabled = true;
      setloader(true);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Projects/Units/Add`,
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("unitsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("marlas").value = "";
            document.getElementById("sarsai").value = "";
            document.getElementById("netmarlas").value = "";
            document.getElementById("plotNet").value = "";
            document.getElementById("unitname").value = "";
            // document.getElementById("dimensionsize").selectedIndex = 0;
            document.getElementById("projectname").selectedIndex = 0;
            document.getElementById("sectionname").selectedIndex = 0;
            document.getElementById("categoryname").selectedIndex = 0;

            // document.getElementById("deals").selectedIndex = 0;
            // document.getElementById("land").selectedIndex = 0;
            document.getElementById("dimensionlenght").value = "";
            document.getElementById("dimentionwidth").value = "";
            document.getElementById("plotRate").value = "";
            setstate({
              UnitId: 0,
              UnitName: null,
              // AreaValue: 0,
              // AreaMeasureUnitId: 0,
              DimensionLength: null,
              DimensionWidth: null,
              // DimensionUnitId: 0,
              SectionId: 0,
              ProjectId: 0,
              Price: null,
              Marla: null,
              Sqft: null,
              NetMarla: null,
              RatePerMarla: null,
              DealBoughtId: null,
              LandPurchaseId: null,
            });
            setSelectedTags([]);
            setprojectid(0);
            setSectionList([]);
            setCategoryList([]);
            setTagsList([]);

            dispatch(getallunits());

            toast.info(result.data.Message);
            // props.cancelunittable();
            setloader(false);
          } else {
            toast.info(result.data.Message);
            setloader(false);
          }
        },
        (error) => {
          document.getElementById("unitsave").disabled = false;

          console.log(error);
          setloader(false);
        }
      );
    }
  };
  // function calculaterate() {

  //     // if (document.getElementById("PlotSQ").value !== null) {
  //     //     let sqfeet = document.getElementById("PlotSQ").value;
  //     //     let totalmarla =
  //     //         parseFloat(sqfeet) / 272.251 +
  //     //         parseInt(document.getElementById("marlas").value);
  //     //     document.getElementById("netmarlas").value = totalmarla.toFixed(4);
  //     // }

  // }
  // function DimenstionCalculation() {

  //     if (document.getElementById("dimentionwidth").value !== null) {
  //         let dimenstionwidth = document.getElementById("dimentionwidth").value;
  //         let dimenstionlength = document.getElementById("dimensionlenght").value;
  //         let totalmarla =
  //             parseFloat(dimenstionwidth) * parseFloat(dimenstionlength) / 270;
  //         let totalsqft =
  //             parseFloat(dimenstionwidth) * parseFloat(dimenstionlength);
  //         console.log(totalmarla);
  //         document.getElementById("netmarlas").value = totalmarla.toFixed(4);
  //         document.getElementById("netsqft").value = parseFloat(totalsqft).toLocaleString();

  //     }

  // }
  function LenghtCalculation() {
    if (document.getElementById("lenghtfeet").value !== null) {
      let lenghtfeet = document.getElementById("lenghtfeet").value;
      let lengthinches = document.getElementById("lenghtinch").value;
      let widthfeet = document.getElementById("widthfeet").value;
      let widthinch = document.getElementById("widthinch").value;

      let cost = document.getElementById("Costpermarla").value;
      let saleRate = document.getElementById("plotRate").value;
      let ftChecked = document.getElementById("radiosqft").checked;

      // let totalmarla =
      //     parseFloat(lenghtfeet) * parseFloat(lengthinches) / 12;
      let totallenght = parseFloat(lenghtfeet) + parseFloat(lengthinches) / 12;
      let totalwidth = parseFloat(widthfeet) + parseFloat(widthinch) / 12;
      // console.log(totalmarla);
      // document.getElementById("netmarlas").value = totalmarla.toFixed(4);
      // document.getElementById("netsqft").value = parseFloat(totalsqft).toLocaleString();
      document.getElementById("dimensionlenght").value =
        parseFloat(totallenght).toFixed(4);
      document.getElementById("dimentionwidth").value =
        parseFloat(totalwidth).toFixed(4);
      document.getElementById("netsqft").value = parseFloat(
        parseFloat(totallenght) * parseFloat(totalwidth)
      ).toLocaleString();
      let totalmarla = (parseFloat(totallenght) * parseFloat(totalwidth)) / 270;
      document.getElementById("netmarlas").value = totalmarla.toFixed(4);

      if (cost && ftChecked) {
        document.getElementById("totalcost").value = (
          parseFloat(parseFloat(totallenght) * parseFloat(totalwidth)) *
          parseFloat(cost.replace(/,/g, ""))
        ).toLocaleString();
      }

      if (saleRate && ftChecked) {
        document.getElementById("plotNet").value = (
          parseFloat(parseFloat(totallenght) * parseFloat(totalwidth)) *
          parseFloat(saleRate.replace(/,/g, ""))
        ).toLocaleString();
      }
    }
  }
  // function WidthCalculation() {

  //     if (document.getElementById("lenghtfeet").value !== null) {
  //         let lenghtfeet = document.getElementById("lenghtfeet").value;
  //         let lengthinches = document.getElementById("lenghtinch").value;
  //         // let totalmarla =
  //         //     parseFloat(lenghtfeet) * parseFloat(lengthinches) / 12;
  //         let totalsqft =
  //             parseFloat(lenghtfeet) + (parseFloat(lengthinches) / 12);
  //         // console.log(totalmarla);
  //         // document.getElementById("netmarlas").value = totalmarla.toFixed(4);
  //         // document.getElementById("netsqft").value = parseFloat(totalsqft).toLocaleString();
  //         document.getElementById("dimensionlenght").value = parseFloat(totalsqft);
  //     }

  // }
  function salerateonchange(e) {
    let currentval = parseInt(
      e.target.value.replace(/,/g, "")
    ).toLocaleString();
    document.getElementById("plotRate").value = currentval;
    if (document.getElementById("radiomarla").checked === true) {
      let marla =
        document.getElementById("marlas") !== null
          ? document.getElementById("marlas").value
          : 0;
      let sarsai =
        document.getElementById("sarsai") !== null
          ? document.getElementById("sarsai").value
          : 0;
      // let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      let marlasarsai = document.getElementById("netmarlas").value;

      let total =
        parseFloat(marlasarsai) * parseFloat(currentval.replace(/,/g, ""));
      let totalfinal = Math.ceil(total);
      document.getElementById("plotNet").value = totalfinal.toLocaleString();
    } else {
      let netsqft = parseFloat(
        document.getElementById("netsqft").value.replace(/,/g, "")
      );
      document.getElementById("plotNet").value = (
        parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ""))
      ).toLocaleString();
    }
  }
  function changeNetMarla() {
    let enteredMarlas = document.getElementById("marlas").value;
    let enteredSarsai = document.getElementById("sarsai").value;
    let marlaChecked = document.getElementById("radiomarla").checked;

    let cost = document.getElementById("Costpermarla").value;
    let saleRate = document.getElementById("plotRate").value;

    if (marlaChecked) {
      let marlasarsai = enteredMarlas.concat(
        ".",
        enteredSarsai,
        enteredSarsai,
        enteredSarsai,
        enteredSarsai
      );
      // document.getElementById("netmarlas").value = marlasarsai;
      // if (cost) {
      //   document.getElementById("totalcost").value = (
      //     parseFloat(marlasarsai) * parseFloat(cost.replace(/,/g, ""))
      //   ).toLocaleString();
      // }

      // if (saleRate) {
      //   document.getElementById("plotNet").value = (
      //     parseFloat(marlasarsai) * parseFloat(saleRate.replace(/,/g, ""))
      //   ).toLocaleString();
      // }
    }
  }
  function radioonchange(e) {
    if (e.target.checked === true) {
      let marla =
        document.getElementById("marlas") !== null
          ? document.getElementById("marlas").value
          : 0;
      let sarsai =
        document.getElementById("sarsai") !== null
          ? document.getElementById("sarsai").value
          : 0;
      let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      // document.getElementById("netmarlas").value = marlasarsai;
    }
  }
  function SQradioonchange(e) {
    if (e.target.checked === true) {
      let dimenstionwidth = document.getElementById("dimentionwidth").value;
      let dimenstionlength = document.getElementById("dimensionlenght").value;
      let totalmarla =
        (parseFloat(dimenstionwidth) * parseFloat(dimenstionlength)) / 270;
      document.getElementById("netmarlas").value = totalmarla.toFixed(4);
    }
  }
  function costrateonchange(e) {
    let currentval = parseInt(
      e.target.value.replace(/,/g, "")
    ).toLocaleString();
    document.getElementById("Costpermarla").value = currentval;
    if (document.getElementById("radiomarla").checked === true) {
      let marla =
        document.getElementById("marlas") !== null
          ? document.getElementById("marlas").value
          : 0;
      let sarsai =
        document.getElementById("sarsai") !== null
          ? document.getElementById("sarsai").value
          : 0;
      // let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      let marlasarsai = document.getElementById("netmarlas").value;

      let total =
        parseFloat(marlasarsai) * parseFloat(currentval.replace(/,/g, ""));
      let totalfinal = Math.ceil(total);
      document.getElementById("totalcost").value = totalfinal.toLocaleString();
      // document.getElementById("totalcost").value = Math.ceil(total);
    } else {
      let netsqft = parseFloat(
        document.getElementById("netsqft").value.replace(/,/g, "")
      );
      document.getElementById("totalcost").value = (
        parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ""))
      ).toLocaleString();
    }
  }
  useEffect(() => {
    dispatch(getallProject());
    dispatch(getallmeasurment());
    dispatch(getalldimension());
    dispatch(getallSection());
    dispatch(getallunits());
    dispatch(getallland());
    dispatch(getallunitcategory());
    dispatch(getallunittags());

    dispatch(getdealboughtpopulatetable());
  }, [dispatch]);
  useEffect(() => {
    setCategoryList(resultsCategory.data);
  }, [resultsCategory.data]);
  useEffect(() => {
    setTagsList(resultsTags.data);
  }, [resultsTags.data]);
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Add Unit</h4>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Project Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <select
            id="projectname"
            className="input_styling  ml-md-3"
            onChange={(e) => {
              if (e.target.value !== "Select") {
                setSectionList(
                  resultsproject.data[
                    document.getElementById("projectname").options[
                      document.getElementById("projectname").selectedIndex
                    ].dataset.idx
                  ]
                );
                setprojectid(
                  document.getElementById("projectname").options[
                    document.getElementById("projectname").selectedIndex
                  ].dataset.id
                );
                // document.getElementById("land").selectedIndex = 0;
                // document.getElementById("deals").selectedIndex = 0;
              }
            }}
          >
            <option>Select</option>
            {resultsproject.data.map((x, index) => (
              <option data-id={x.ProjectId} data-idx={index} key={index}>
                {x.ProjectName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
          {/* <select className="input_styling ml-md-3" id="projectname" >
                        <option>Select</option>
                        {resultsproject.data.map((val, index) => (
                            <option key={index} data-id={val.ProjectId}>{val.ProjectName}</option>
                        ))}
                    </select> */}
        </div>
        <div className="col-md-1 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">
            {" "}
            {stateSectionList !== undefined
              ? stateSectionList.ProjectOwner === "PERSONAL"
                ? "Land Name"
                : "Deal Name"
              : null}
          </label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          {stateSectionList !== undefined ? (
            stateSectionList.ProjectOwner === "PERSONAL" ? (
              <>
                {" "}
                <select className="input_styling ml-md-3" id="land">
                  <option data-id={0}>Select</option>
                  {resultallland.data.map((val, index) =>
                    parseInt(stateprojectid) ===
                    parseInt(val.Project.ProjectId) ? (
                      <option key={index} data-id={val.LandPurchasedId}>
                        {val.LandName}
                      </option>
                    ) : null
                  )}
                </select>
                <span className="estaric">*</span>{" "}
              </>
            ) : (
              <>
                {" "}
                <select className="input_styling ml-md-3" id="deals">
                  <option data-id={0}>Select</option>
                  {resultsalldealbought.data.map((val, index) =>
                    parseInt(stateprojectid) ===
                    parseInt(val.Project.ProjectId) ? (
                      <option key={index} data-id={val.DealBoughtId}>
                        {val.DealName}
                      </option>
                    ) : null
                  )}
                </select>
                <span className="estaric">*</span>
              </>
            )
          ) : null}
        </div>
      </div>
      {/* <div className="row ">
                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0 " >Land Name:</label>
                </div>
                <div className="col-md-3 mt-4  p-0">
                    {stateSectionList !== undefined ? stateSectionList.ProjectOwner === "PERSONAL" ?
                        <select className="input_styling ml-md-3" id="land"  >
                            <option data-id={0}>Select</option>
                            {resultallland.data.map((val, index) => (
                                parseInt(stateprojectid) === parseInt(val.Project.ProjectId) ? <option key={index} data-id={val.LandPurchasedId}>{val.LandName}</option> : null
                            ))}
                        </select> :
                        <select className="input_styling ml-md-3" id="land" disabled >
                            <option data-id={0}>Select</option>
                            {resultallland.data.map((val, index) => (
                                parseInt(stateprojectid) === parseInt(val.Project.ProjectId) ? <option key={index} data-id={val.LandPurchasedId}>{val.LandName}</option> : null
                            ))}
                        </select>
                        : null}

                </div>
                <div className="col-md-1 mt-4 p-0">
                </div>
                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0">Deals:</label>
                </div>
                <div className="col-md-2 mt-4   p-0">
                    {stateSectionList !== undefined ? stateSectionList.ProjectOwner === "Other" ?
                        <select className="input_styling ml-md-3" id="deals" >
                            <option data-id={0}>Select</option>
                            {resultsalldealbought.data.map((val, index) => (
                                parseInt(stateprojectid) === parseInt(val.Project.ProjectId) ? <option key={index} data-id={val.DealBoughtId}>{val.DealName}</option> : null
                            ))}
                        </select> :
                        <select className="input_styling ml-md-3" id="deals" disabled>
                            <option data-id={0}>Select</option>
                            {resultsalldealbought.data.map((val, index) => (
                                parseInt(stateprojectid) === parseInt(val.Project.ProjectId) ? <option key={index} data-id={val.DealBoughtId}>{val.DealName}</option> : null
                            ))}
                        </select>
                        : null}

                </div>

            </div> */}
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Section:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <select id="sectionname" className="input_styling  ml-md-3">
            <option>Select</option>
            {stateSectionList !== undefined &&
            stateSectionList.Sections !== undefined
              ? stateSectionList.Sections.map((x, index) => (
                  <option data-id={x.SectionId} data-idx={index} key={index}>
                    {x.SectionName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
          {/* <select className="input_styling ml-md-3" id="sectionname" >
                        <option>Select</option>
                        {resultsallsections.data.map((val, index) => (
                            <option key={index} data-id={val.SectionId}>{val.SectionName}</option>
                        ))}
                    </select> */}
        </div>
        <div className="col-md-1 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Unit Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input type="text" className="input_styling ml-md-3" id="unitname" />
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0">Dimension:</label>
                </div>
                <div className="col-md-1 mt-4  p-0">
                    <input type="number" className="input_styling ml-md-3 " id="dimensionlenght" placeholder="length" />


                </div>
                <div className="col-md-1 mt-4 p-0">
                    <input type="number" className="input_styling ml-md-3 " id="dimentionwidth" placeholder="width" />


                </div>
                <div className="col-md-2 mt-4   p-0">
                    <select className="input_styling ml-md-3" id="dimensionsize" >
                        <option>Select</option>
                        {resultsdimensions.data.map((val, index) => (
                            <option key={index} data-id={val.DimensionUnitId}>{val.DimensionUnit}</option>
                        ))}
                    </select>

                </div> */}
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Category:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <select id="categoryname" className="input_styling  ml-md-3">
            <option>Select</option>
            {stateCategoryList !== undefined
              ? stateCategoryList.map((x, index) => (
                  <option
                    data-id={x.UnitCategoryId}
                    data-idx={index}
                    key={index}
                  >
                    {x.CategoryName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Tags:</label>
        </div>
        <div
          className="col-md-3 mt-4  p-0"
          style={{
            display: "flex",

            justifyContent: "space-evenly",
          }}
        >
          {stateTagsList.map((x, index) => (
            <div>
              <span className="txt"> {x.TagName}</span>{" "}
              <input
                key={index}
                type="checkbox"
                id={`myCheck-${index}`}
                checked={selectedTags.some((t) => t.tagId === x.TagId)}
                onClick={(e) => {
                  if (e.target.checked) {
                    // Add object to state

                    setSelectedTags([...selectedTags, { tagId: x.TagId }]);
                  } else {
                    // Remove object from state
                    setSelectedTags(
                      selectedTags.filter((t) => t.tagId !== x.TagId)
                    );
                  }
                }}
              />
            </div>
          ))}
        </div>
        {/* 
        <div className="col-md-3 mt-4  p-0">
          <select id="tagname" className="input_styling  ml-md-3">
            <option>Select</option>
            {stateTagsList !== undefined
              ? stateTagsList.map((x, index) => (
                  <option data-id={x.TagId} data-idx={index} key={index}>
                    {x.TagName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div> */}
      </div>
      {/* dimenstion */}
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Lenght(ft):</label>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="lenghtfeet"
            placeholder="feet"
            onChange={() => {
              LenghtCalculation();
            }}
          />
        </div>
        <div className="col-md-1 mt-4 p-0">
          <input
            type="number"
            className="input_styling ml-md-2 "
            id="lenghtinch"
            placeholder="inch"
            onChange={() => {
              LenghtCalculation();
            }}
          />
        </div>
        <div className="col-md-1 mt-4 p-0">
          <input
            type="number"
            className="input_styling "
            id="dimensionlenght"
            placeholder="Total"
            disabled
          />
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-1 mt-4  p-0">
                    <input type="number" className="input_styling ml-md-3 " id="dimensionlenght" placeholder="length" onChange={() => { DimenstionCalculation() }} />


                </div>
                <div className="col-md-1 mt-4 p-0">
                    <input type="number" className="input_styling ml-md-3 " id="dimentionwidth" placeholder="width" onChange={() => { DimenstionCalculation() }} />


                </div> */}
        <div className="col-md-1 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Width(ft):</label>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="widthfeet"
            placeholder="feet"
            onChange={() => {
              LenghtCalculation();
            }}
          />
        </div>
        <div className="col-md-1 mt-4 p-0">
          <input
            type="number"
            className="input_styling ml-md-2 "
            id="widthinch"
            placeholder="inch"
            onChange={() => {
              LenghtCalculation();
            }}
          />
        </div>
        <div className="col-md-1 mt-4 p-0">
          <input
            type="number"
            className="input_styling  "
            id="dimentionwidth"
            placeholder="Total"
            disabled
          />
          <span className="estaric">*</span>
        </div>
      </div>
      {/* area value */}
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Area Value:</label>
        </div>

        <div className="col-md-1 mt-4   p-0">
          <input
            type="number"
            id="marlas"
            className="input_styling ml-md-3 "
            placeholder="marla"
            // onChange={calculaterate}
            onChange={changeNetMarla}
          />
        </div>
        <div className="col-md-1 mt-4   p-0">
          <input
            type="number"
            id="sarsai"
            className="input_styling ml-md-2 "
            placeholder="sarsai"
            // onChange={() => {
            //     calculaterate();
            // }}
            onChange={changeNetMarla}
          ></input>
        </div>

        <div className="col-md-2 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Net Sq/ft:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="netsqft"
            disabled
          />
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Rate Per:</label>
        </div>
        <div className="col-md-1 mt-4 text-center  p-0">
          <span>Marla</span>
          <input
            type="radio"
            name="radiomarla"
            className="ml-2"
            id="radiomarla"
            onChange={(e) => {
              document.getElementById("Costpermarla").value = "";
              document.getElementById("totalcost").value = "";
              document.getElementById("plotRate").value = "";
              document.getElementById("plotNet").value = "";
              radioonchange(e);
            }}
          />
        </div>
        <div className="col-md-1 text-center  mt-4  p-0">
          <span>Sq.Ft</span>
          <input
            type="radio"
            name="radiomarla"
            className="ml-2"
            id="radiosqft"
            defaultChecked
            onChange={(e) => {
              document.getElementById("Costpermarla").value = "";
              document.getElementById("totalcost").value = "";
              document.getElementById("plotRate").value = "";
              document.getElementById("plotNet").value = "";
              SQradioonchange(e);
            }}
          />
        </div>
        <div className="col-md-2 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Net Marla:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling ml-md-3"
            id="netmarlas"
            onChange={(e) => {
              if (
                e.target.value &&
                document.getElementById("radiomarla").checked === true
              ) {
                console.log(e.target.value);
                console.log(
                  document.getElementById("Costpermarla").value,
                  document.getElementById("plotRate").value
                );

                let rate = document
                  .getElementById("Costpermarla")
                  .value?.replace(/,/g, "");
                if (rate !== "") {
                  document.getElementById("totalcost").value = (
                    parseFloat(e.target.value) * parseFloat(rate)
                  ).toLocaleString();
                } else {
                  document.getElementById("totalcost").value = "";
                }
                let salerate = document
                  .getElementById("plotRate")
                  .value.replace(/,/g, "");
                if (rate !== "") {
                  document.getElementById("plotNet").value = (
                    parseFloat(e.target.value) * parseFloat(salerate)
                  )?.toLocaleString();
                } else {
                  document.getElementById("plotNet").value = "";
                }
              } else {
                document.getElementById("totalcost").value = "";
                document.getElementById("plotNet").value = "";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      {/* <div className="row ">
                <div className="col-md-2 mt-4  text-left p-0">
                    <label className="input_label m-0 " >Unit Name:</label>
                </div>
                <div className="col-md-3 mt-4  p-0">
                    <input className="input_styling ml-md-3" id="unitname" />


                </div>


            </div> */}
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Cost Rate:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Costpermarla"
            onChange={(e) => {
              if (e.target.value.length > 0) {
                costrateonchange(e);
              } else {
                document.getElementById("totalcost").value = "";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Sale Rate:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="plotRate"
            onChange={(e) => {
              if (e.target.value.length > 0) {
                salerateonchange(e);
              } else {
                document.getElementById("plotNet").value = "";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Total Cost Amt:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            disabled
            id="totalcost"
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Total Sale Amt:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="plotNet"
            disabled
          />
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row pr-md-2 pt-md-4">
        <div className=" col-6 pr-0 pl-0  "></div>
        <div className=" col-6 pr-0 pl-0  text-right ">
          <span>
            {loader ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </span>
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={props.cancelunittable}
            >
              Cancel
            </button>
          </span>
          <span>
            <button className="save_btn  ml-2" id="unitsave" onClick={savedata}>
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
      <hr></hr>
      {resultsallunits.loading ? (
        <Loader type="ThreeDots" color="green" height={40} width={40} />
      ) : null}
      <div className="table-responsive mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Project</th>
              <th>Section </th>
              <th>Unit</th>
              <th>Marla</th>
              <th>Dimension</th>
              <th>S.Rate</th>
              <th>Total Value</th>
            </tr>
          </thead>
          <tbody>
            {resultsallunits.data.map((val, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {val.Project !== null && val.Project !== undefined ? (
                  <td>{val.Project.ProjectName}</td>
                ) : (
                  <td></td>
                )}
                {val.Section !== null && val.Section !== undefined ? (
                  <td>{val.Section.SectionName}</td>
                ) : (
                  <td></td>
                )}
                <td>{val.UnitName}</td>

                {/* <td>{val.NetMarla}</td> */}
                <td>{val.NetMarla}</td>
                <td>{val.DimensionLength + "x" + val.DimensionWidth}</td>
                <td>{parseFloat(val.RatePerMarla).toLocaleString()}</td>

                <td>{parseFloat(val.Price).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
