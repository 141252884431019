import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getallunits } from "../../../actions/getunit";

function DeleteUnitDialog({ selectedRow, setselectedRow }) {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  async function deleteContractVoucher() {
    if (document.getElementById("contractVoucherEmail").value === "") {
      return toast.info("Email is Required");
    } else if (
      document.getElementById("contractVoucherPassword").value === ""
    ) {
      return toast.info("Password is Required");
    } else if (document.getElementById("contractVoucherReason").value === "") {
      return toast.info("Reason is Required");
    }

    const Email = document.getElementById("contractVoucherEmail").value;
    const Password = document.getElementById("contractVoucherPassword").value;
    const Reason = document.getElementById("contractVoucherReason").value;
    // const data={
    //     Email:Email,
    //   Password:Password,

    // }
    setloading(true);
    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAvailableUnit?unitId=${selectedRow?.UnitId}&Email=${Email}&Password=${Password}&Reason=${Reason}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          toast.info(result.data.Message);
          document.getElementById("contractVoucherEmail").value = "";
          document.getElementById("contractVoucherPassword").value = "";
          document.getElementById("contractVoucherReason").value = "";
          dispatch(getallunits());
          setselectedRow(null);
        } else {
          toast.info(result.data.Message);

          // setvoucherRowdata(null)
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
    setloading(false);
  }
  return (
    <div id="mymodal2" class="modal" style={{ display: "block" }}>
      <div class="modal-content-cat_voucher2">
        <div className="row ">
          <div className="col-12  page_heading">
            <h4 className="  text-center ">Revert Transfer Unit</h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setselectedRow(null);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <div className="container">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Email:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Email"
                id="contractVoucherEmail"
                type="email"
              ></input>
            </div>
            <div className="col-12 " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Password:</label>
            </div>
            <div className="col-12 ">
              <input
                style={{ width: "100%" }}
                className="input_total_styling "
                placeholder="Password"
                id="contractVoucherPassword"
              ></input>
            </div>
            <div className="col-12  " style={{ paddingTop: "10px" }}>
              <label className="input_label m-0">Reason:</label>
            </div>
            <div className="col-12  " style={{ width: "100%" }}>
              <textarea
                style={{ width: "100%", height: "100px" }}
                className="input_total_styling "
                placeholder="Reason"
                id="contractVoucherReason"
                rows="4"
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                float: "right",
                width: "100%",
                justifyContent: "end",
                marginRight: "3%",
                marginTop: "10px",
                alignItems: "center",
                gap: 5,
              }}
            >
              <>
                {loading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}

                <button
                  className="cancel_btn"
                  onClick={() => {
                    setselectedRow(null);
                  }}
                >
                  Cancel
                </button>

                <button
                  className="save_btn"
                  disabled={loading}
                  onClick={deleteContractVoucher}
                >
                  Save
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteUnitDialog;
