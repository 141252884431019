import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { TiPrinter } from "react-icons/ti";

import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import moment from "moment";
import { RecoveryReceiptReportPrint } from "../ReportPrints/RecoveryReceiptPrint";
import { RecoveryIndividualPrint } from "../ReportPrints/RecoveryReceiptIndividualPrint";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

export const RecoveryReceiptReport = () => {
  const [recovery, setrecovery] = useState([]);
  const [recoveryfilter, setrecoveryfilter] = useState([]);
  const [recoveryfilter2, setrecoveryfilter2] = useState([]);
  const [recoveryfilter3, setrecoveryfilter3] = useState([]);
  const [recoveryfilter4, setrecoveryfilter4] = useState([]);
  const [loader1, setloader1] = useState(false);
  const [month, setmonth] = useState();
  const [totalval, settotalval] = useState(0);
  const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
  const [multiple, setmultiple] = useState();
  const [sumpending, setsumpending] = useState(0);
  const [accounttitle, setaccounttitle] = useState(0);
  const [totalDue, setTotalDue] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalReceivable, setTotalReceivable] = useState(0);
  const [csvData, setcsvData] = useState([]);

  const [queryDates, setqueryDates] = useState({
    start: localStorage.getItem("OpeningYear"),
    end: moment().format("YYYY-MM-DD"),
  });
  const [showTotal, setShowTotal] = useState(true);

  //Table Data fiter
  var suggestionlist = [];

  const onTextChange = (e) => {
    let Total = 0;
    const value = e.target.value;
    if (value === "") {
      setShowTotal(true);
      setrecoveryfilter(recoveryfilter2);
      // if (recoveryfilter2 !== undefined) {
      //   recoveryfilter2.map((val, index) => {
      //     Total += parseFloat(val.CustomerReceivable);
      //   });
      //   settotalval(Total);
      // }
    } else {
      setShowTotal(false);
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = recoveryfilter2
          .sort()
          .filter((v) =>
            v.ContractInstallments !== null && v.ContractInstallments.length > 0
              ? regex.test(v.CustomerCnicNavigation.CustomerName) ||
                regex.test(v.Unit.UnitName)
              : null
          );
      }
      suggestionlist = suggestions;

      if (suggestionlist.length === 0) {
        setrecoveryfilter([]);
      } else {
        setrecoveryfilter(suggestionlist);
        setrecoveryfilter3(suggestionlist);
      }
      // if (suggestionlist !== undefined) {
      //   suggestionlist.map((val, index) => {
      //     Total += parseFloat(val.CustomerReceivable);
      //   });
      //   settotalval(Total);
      // }
    }
  };

  useEffect(() => {
    savedata();
  }, []);

  var i = 0;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const openmodaljournaltable = (x) => {
    // setaccounttitle(x.accounttitle);
    let sum = 0;
    x?.ContractInstallments?.forEach((y) => {
      sum = sum + (y.DueAmount - y.ReceivedAmount);
    });
    setsumpending(sum);

    setmultiple(x);
    document.getElementById("modalAddnewJournal").style.display = "block";
  };

  const savedata = (disableBtn) => {
    if (disableBtn) {
      document.getElementById("go").disabled = true;
    }
    if (
      document.getElementById("fromdate").value === "" ||
      document.getElementById("todate").value === ""
    ) {
      toast.info("Select From Date and To Date");
      if (disableBtn) {
        document.getElementById("go").disabled = false;
      }
    } else {
      setloader1(true);

      axios({
        url: `${
          process.env.REACT_APP_API_URL
        }Reports/InstallmentsRecoveryNew?ProjectId=${
          localStorage.getItem("ProjectId")
            ? localStorage.getItem("ProjectId")
            : ""
        }&StartDate=${queryDates.start}&EndDate=${queryDates.end}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (res) => {
          if (res?.data?.IsSuccess) {
            console.log("res", res);
            setrecovery(res?.data?.Recovery);
            setrecoveryfilter(res?.data?.Recovery);
            setsuggestionbuyersdata(res?.data?.Recovery);
            setrecoveryfilter2(res?.data?.Recovery);
            setrecoveryfilter3(res?.data?.Recovery);
            setrecoveryfilter4(res?.data?.Recovery);
            setTotalDue(res?.data?.TotalDueAmount);
            setTotalReceived(res?.data?.TotalReceivedAmount);
            setTotalReceivable(res?.data?.TotalReceivable);
            // let totalsum = 0;
            // if (
            //   res.data.Recovery !== undefined &&
            //   res.data.Recovery !== null &&
            //   res.data.Recovery.length > 0
            // ) {
            //   res.data.Recovery.map((x) => {
            //     totalsum += parseFloat(x.CustomerReceivable);
            //   });
            //   toast.success(res?.data?.message);
            // } else {
            //   toast.error(res?.data?.message);
            // }
            // settotalval(totalsum);
          }
          setloader1(false);
          if (disableBtn) {
            document.getElementById("go").disabled = false;
          }
        },
        (error) => {
          toast.error(error?.message);
          setloader1(false);
          if (disableBtn) {
            document.getElementById("go").disabled = false;
          }
        }
      );
    }
  };

  const componentRef1 = useRef();
  const handlePrintIndividual = useReactToPrint({
    content: () => componentRef1.current,
  });
  useEffect(() => {
    let arr = [];
    recoveryfilter?.forEach((x) => {
      let arr2 = [];
      x.ContractInstallments.map((item) => {
        if (item.InstallmentStatus === "PENDING") {
          arr2.push(item);
        }
      });
      arr.push({
        ["Contract Date"]: moment(x?.EntryDate?.split("T")[0]).format(
          "DD-MMM-YYYY"
        ),
        Buyer: x?.CustomerCnicNavigation?.CustomerName,
        ["Contact #"]: x?.CustomerCnicNavigation?.ContactNumber1,
        Unit: x?.Unit?.UnitName,
        ["Total Installments"]: x?.NoOfInstallments,
        ["Pending Installments"]: arr2?.length,
        ["Total Price"]: x?.TotalPrice?.toLocaleString(),
        ["Customer Receivable"]: x?.CustomerReceivable?.toLocaleString(),
        ["Due Installment"]: x.ContractInstallments.reduce(
          (total, installment) =>
            total + (installment.DueAmount - installment.ReceivedAmount),
          0
        )?.toLocaleString(),
        ["Last Payment Date"]:
          x?.UnitVoucher[0]?.EntryDate &&
          moment(x?.UnitVoucher[0]?.EntryDate?.split("T")[0]).format(
            "DD-MMM-YYYY"
          ),
      });
    });

    arr.push({
      ["Contract Date"]: "",
      Buyer: "",
      ["Contact #"]: "",
      Unit: "",
      ["Total Installments"]: "",
      ["Pending Installments"]: "",
      ["Total Price"]: "",
      ["Customer Receivable"]: "",
      ["Due Installment"]: "",
      ["Last Payment Date"]: "",
    });
    arr.push({
      ["Contract Date"]: "",
      Buyer: "Total Due",
      ["Contact #"]: parseFloat(totalDue)?.toLocaleString(),
      Unit: "",
      ["Total Installments"]: "Total Recieved",
      ["Pending Installments"]: parseFloat(totalReceived)?.toLocaleString(),
      ["Total Price"]: "",
      ["Customer Receivable"]: "Total Receivable:",
      ["Due Installment"]: parseFloat(totalReceivable)?.toLocaleString(),
      ["Last Payment Date"]: "",
    });

    setcsvData(arr);
  }, [recoveryfilter, totalReceived, totalReceivable, totalDue]);
  return (
    <>
      <div hidden>
        <RecoveryIndividualPrint
          ref={componentRef1}
          multiple={multiple}
          sumpending={sumpending}
          // clientName={accounttitle}
        />
      </div>
      <div id="modalAddnewJournal" class="modalscreen ">
        <div class="modal-content-screen1">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Installments Detail</h4>
              <span
                class="close"
                onClick={() => {
                  document.getElementById("modalAddnewJournal").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>

          <div className="container">
            <table class="table table-hover">
              <thead class="purple whiteText tableCash">
                <tr className="purple whiteText">
                  <th>Sr.</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Installment Amount</th>
                  <th scope="col">Received</th>
                  <th scope="col">Pending Amount</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>

              <tbody>
                {multiple?.ContractInstallments.map((y, i) => (
                  <tr>
                    <td scope="row">{++i}</td>
                    <td>
                      {moment(y?.DueDate?.split("T")[0]).format("DD-MMM-YYYY")}
                    </td>
                    <td>{y?.DueAmount?.toLocaleString()}</td>
                    <td>{y?.ReceivedAmount?.toLocaleString()}</td>
                    <td>
                      {(y?.DueAmount - y?.ReceivedAmount)?.toLocaleString()}
                    </td>
                    <td>{y?.InstallmentStatus}</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Pending Sum:</b>
                  </td>
                  <td className="ml-2">{sumpending?.toLocaleString()}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="row mt-1">
            <div className="col-md-3  "></div>
            <div className="col-md-6  "></div>
            <div className="col-md-3  ">
              <div className="col-md-6 float-right">
                {/* <img src={printerPic} alt="printer" className="printer"></img> */}
                <button
                  className="print_btn "
                  onClick={() => {
                    handlePrintIndividual();
                  }}
                >
                  <TiPrinter size="1.3em" />
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div hidden>
        <RecoveryReceiptReportPrint
          ref={componentRef}
          recoveryfilter={recoveryfilter}
          // totalval={totalval}
          month={month}
          showTotal={showTotal}
          totalDue={totalDue}
          totalReceived={totalReceived}
          totalReceivable={totalReceivable}
        />
      </div>

      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className=" tableContainer2 pageheading Sitka">
            <div className="col-12 p-0  ">
              <div className="loaderclass"></div>
              {
                /* <div className="row ">
                <div className="col-md-1 pr-0 pl-0">
                  <label className="input_label m-0"> Month :</label>
                </div>
                <div className="col-md-3 pr-0 pl-0">
                  <input
                    id="Monthvalue"
                    type="month"
                    className="input_styling "
                    onChange={setMonth}
                  />
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <button
                    id="callapibutn"
                    onClick={() => {
                      document.getElementById("Monthvalue").value = null;
                      document.getElementById("advancefilter").checked = false;
                      document.getElementById(
                        "installmentsfilter"
                      ).checked = false;
                      let Total = 0;
                      if (suggestionbuyersdata !== undefined) {
                        suggestionbuyersdata.map((val, index) => {
                          Total += parseFloat(val.CustomerReceivable);
                        });
                        settotalval(Total);
                      }
                      setrecoveryfilter(recovery);
                    }}
                    className="print_btn  text-right ml-2 "
                  >
                    Clear
                  </button>
                </div>*/
                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text"
                    onChange={onTextChange}
                    autoComplete="off"
                    placeholder="Search By Customer/Unit"
                    className="input_styling"
                  ></input>
                </div>
                /* <div className="col-md-3 pr-0 pl-0">
                  <input
                    id="advancefilter"
                    onChange={handleAdvance}
                    className="m-1"
                    type="checkbox"
                  />{" "}
                  Due Advance
                  <input
                    id="installmentsfilter"
                    onChange={handleInstallments}
                    className="m-1 ml-2"
                    type="checkbox"
                  />{" "}
                  Due Installments
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <div>
                    {loader1 && (
                      <Loader
                        type="ThreeDots"
                        color="green"
                        height={40}
                        width={40}
                      />
                    )}
                  </div>
                </div>
              </div> */
              }
              {/* <div className="row  pt-3">


                                <div className="col-md-6 pr-0 pl-0">
                                </div>
                                <div className="col-md-2 text-right pr-0 pl-0">

                                </div>
                            </div> */}
              <div className="row mt-3">
                <div className="col-md-3 text-center  pr-0 pl-0">
                  <label className="input_label m-0">From:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    onChange={(e) => {
                      setqueryDates({ ...queryDates, start: e.target.value });
                    }}
                    value={queryDates.start}
                    id="fromdate"
                  />
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                  <label className="input_label m-0">To:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    id="todate"
                    onChange={(e) => {
                      setqueryDates({ ...queryDates, end: e.target.value });
                    }}
                    value={queryDates.end}
                  />
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <button
                    className="btn_Go"
                    onClick={() => savedata(true)}
                    id="go"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  {loader1 && (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className=" col-12  table_height pr-0 pl-0">
              <div className="table-responsive mt-2">
                <table className="table table-borderless  table-hover m-0">
                  <thead>
                    <tr className="purple whiteText">
                      <th>Sr.</th>
                      <th>Contract Date</th>
                      <th>Buyer</th>
                      <th>Contact No</th>
                      <th>Unit</th>
                      <th>Total Installments</th>
                      <th>Pending Installments</th>
                      <th>Total Price</th>
                      <th>Customer Receivable</th>
                      <th>Due Installment Amt.</th>
                      <th>Last Payment Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recoveryfilter?.map((x, index) => {
                      let arr = [];
                      x.ContractInstallments.map((item) => {
                        if (item.InstallmentStatus === "PENDING") {
                          arr.push(item);
                        }
                      });
                      return (
                        x?.ContractInstallments?.length > 0 && (
                          <tr
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              openmodaljournaltable(x);
                            }}
                          >
                            <td>{++i}</td>
                            <td className="th2 tdcell">
                              {moment(x?.EntryDate?.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td className="th2 tdcell">
                              {x?.CustomerCnicNavigation?.CustomerName}
                            </td>
                            <td className="th2 tdcell">
                              {x?.CustomerCnicNavigation?.ContactNumber1}
                            </td>
                            <td>{x?.Unit?.UnitName}</td>
                            <td className="th2 tdcell">
                              {x?.NoOfInstallments}
                            </td>
                            <td>{arr?.length}</td>
                            <td className="th2 tdcell">
                              {x?.TotalPrice?.toLocaleString()}
                            </td>
                            <td className="th2 tdcell">
                              {x?.CustomerReceivable?.toLocaleString()}
                            </td>
                            <td className="th2 tdcell">
                              {x.ContractInstallments.reduce(
                                (total, installment) =>
                                  total +
                                  (installment?.DueAmount -
                                    installment?.ReceivedAmount),
                                0
                              )?.toLocaleString()}
                            </td>
                            <td className="th2 tdcell">
                              {x?.UnitVoucher[0]?.EntryDate &&
                                moment(
                                  x?.UnitVoucher[0]?.EntryDate?.split("T")[0]
                                ).format("DD-MMM-YYYY")}
                            </td>
                          </tr>
                        )
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row mt-1">
                <div className="col-md-10">
                  <div
                    className="row"
                    style={{
                      display: showTotal ? "flex" : "none",
                    }}
                  >
                    <div className="col-md-4">
                      <label className="input_label m-0">Total Due:</label>
                      <input
                        type="text"
                        id="credittotal"
                        value={parseFloat(totalDue)?.toLocaleString()}
                        readOnly
                        className=" ml-1 input_styling"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="input_label m-0">Total Received:</label>
                      <input
                        type="text"
                        id="credittotal"
                        value={parseFloat(totalReceived)?.toLocaleString()}
                        readOnly
                        className=" ml-1 input_styling"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="input_label m-0">
                        Total Receivable:
                      </label>
                      <input
                        type="text"
                        id="credittotal"
                        value={parseFloat(totalReceivable)?.toLocaleString()}
                        readOnly
                        className=" ml-1 input_styling"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 text-right ">
                  <div className="row mt-3">
                    <button
                      className="print_btn "
                      id="callapibutn"
                      onClick={() => {
                        handlePrint();
                      }}
                    >
                      <TiPrinter size="1.3em" />
                      Print
                    </button>
                    {csvData.length > 0 && (
                      <button
                        style={{ color: "black !important" }}
                        className="print_btn ml-2 float-left"
                      >
                        <CSVLink
                          style={{ color: "black" }}
                          filename="Recovery Report"
                          data={csvData}
                        >
                          Download CSV
                        </CSVLink>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
